import React from "react";
import { DEFAULT_LANGAGE } from "configs/constants";
import { useMutationLangage, useQueryMe, useQueryStore } from "hooks";
import {
  useQueryRenumerationSettings,
  useQuerySettings,
  useQuerySteps,
} from "hooks/settings";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-au";
import { navigationBreadcrumbs } from "configs/helper-contants";
import BreadCrumbs from "components/@vuexy/breadCrumbs/BreadCrumb";
import Token from "configs/Token";
import * as LanguageFr from "./language-fr.json";
import * as LanguageTr from "./language-tr.json";

const LangageContext = React.createContext();

const langageLabels = {
  fr: "Français",
  en: "English",
  tr: "Turkish",
};
const LangProvider = (props) => {
  const [lang, setLang] = React.useState(DEFAULT_LANGAGE);
  const [currency, setCurrency] = React.useState("$");
  const { data } = useQueryMe();
  const { data: storeData, refetch: refetchStore } = useQueryStore(
    Token.getStoreId()
  );
  const [labels, setLabels] = React.useState(null);
  const { updateCurrentLangage } = useMutationLangage();
  const { data: dataSettings, refetch } = useQuerySettings();
  const { data: renumerationsSettings } = useQueryRenumerationSettings();
  const { completedConfiguration } = useQuerySteps();
  const [pageTitle, setPageTitle] = React.useState("Accueil");
  const [useElasticSearch, setUseElasticSearch] = React.useState(false);
  const handleChangeLang = (lg) => {
    setLang(lg);
    updateCurrentLangage({
      langage: {
        langageCode: lg,
        langageLabel: langageLabels[lg],
      },
    });
  };

  React.useEffect(() => {
    if (storeData?.id) {
      if (lang !== "tr") setLang("tr");
      setCurrency("₺");
    }

    setLabels(storeData?.id ? LanguageTr?.default : LanguageFr?.default);

    if (lang === "en") {
      moment.locale("en-au");
    } else {
      moment.locale(lang);
    }

    /* const { labels: common1 } = common.data; */

    // setLabels(langage[lang]);
  }, [dataSettings, storeData, lang, currency]);
  React.useEffect(() => {}, [lang, labels]);
  /*  React.useEffect(() => {
    if (data) {
      setLang(
        data?.langage?.langageCode
          ? data?.langage?.langageCode
          : DEFAULT_LANGAGE
      );
    }
  }, [data]); */
  React.useEffect(() => {
    if (dataSettings) {
      document.title = dataSettings?.appName;
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = dataSettings?.appResponsiveLogo;
  }, [dataSettings]);
  const isVisibleCol = ({ collection = null, col = null }) => {
    if (!collection || !col) return true;
    return !dataSettings?.[collection]?.hidden?.includes(col);
  };
  function renderPageBreadcrumbs(path, props = null) {
    const find = navigationBreadcrumbs(labels)?.find(
      (route) => route?.path === path
    );
    return <BreadCrumbs {...find} {...props} />;
  }

  return (
    <LangageContext.Provider
      value={{
        handleChangeLang,
        labels,
        lang,
        currency,
        settings: dataSettings,
        completedConfiguration,
        isVisibleCol,
        pageTitle,
        setPageTitle,
        useElasticSearch,
        renderPageBreadcrumbs,
        refetchSetting: refetch,
        storeData,
        refetchStore,
        renumerationsSettings,
      }}
    >
      {props.children}
    </LangageContext.Provider>
  );
};
const renderPageBreadcrumbsFunc = (path, props = null) => {
  const find = navigationBreadcrumbs(null)?.find(
    (route) => route?.path === path
  );
  return <BreadCrumbs {...find} {...props} />;
};
const useTranslate = () => React.useContext(LangageContext);
const useGlobalSettings = () => React.useContext(LangageContext);
export {
  LangageContext,
  LangProvider,
  useTranslate,
  useGlobalSettings,
  renderPageBreadcrumbsFunc,
};
