import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";

const REFUND = gql`
  query getRefund($id: ID) {
    getRefund(id: $id) {
      id
      store {
        id
        name
      }
      client {
        lastName
        firstName
      }
      command {
        checkout {
          id
          num
        }
        itemsCount
        florist {
          id
          name
          photoUrl
          address
          city
          storeCountry {
            name
          }
          siret
        }
        cardboards {
          id
          num
          qrcode
          takenFromSupplier
          storedForSending
          onWayToDestination
          storedFordelivering
          delivering
          delivered
          variant {
            id
            weight
            width
            height
            depth
            weightCarton
            photoUrl
            colorTr
            values {
              label
              value
            }
          }
          product {
            id
            translations {
              country
              name
              description
            }
          }
        }

        packagingList
        state
        captured
        delivrable
        status {
          state
          date
        }
        promotion {
          id
          code
          value
          promotionType
          enabled
          discountType
        }
        oldItemsStore {
          _id
          variant {
            id
            product
            refVariation
            price
            photoUrl
            weight
            values {
              label
              value
            }
          }
          product {
            id
            translations {
              country
              name
              description
            }
            photoUrls {
              photoUrl
            }
            enableSimpleProduct
            enableMultiVariation
            enableAddons
          }
          quantity
          promotion {
            id
            title
            value
            promotionType
          }
        }
        oldItems {
          _id
          variant {
            id
            product
            refVariation
            photoUrl
            price
            weight
            values {
              label
              value
            }
          }
          product {
            id
            translations {
              country
              name
              description
            }
            photoUrls {
              photoUrl
            }
            enableSimpleProduct
            enableMultiVariation
            enableAddons
          }
          quantity
          promotion {
            id
            title
            value
            promotionType
          }
        }
        items {
          _id
          variant {
            id
            product
            refVariation
            photoUrl
            price
            weight
            values {
              label
              value
            }
          }
          product {
            id
            translations {
              country
              name
              description
            }
            photoUrls {
              photoUrl
            }
            enableSimpleProduct
            enableMultiVariation
            enableAddons
          }
          quantity
          promotion {
            id
            title
            value
            promotionType
          }
        }
        client {
          id
          firstName
          lastName
          tel
          fullName
          profile {
            email
          }
          photoUrl
        }
        deliveryAddress {
          firstName
          lastName
          address
          deliveryMode
          address1
          phone
          descrption
        }
        num
        createdAt
        totalPrice
        subtotalPrice
        deliveryMode
        paymentMethods
        deliveryTime
        deliveredBy
        paymentMode
        paymentState
        deliveryFee
        feeService
        totalTax
        message
        rates
        commission
        deliveryCancellationRaison {
          raison
          canceledDate
          canceledBy
          refunded
        }
        shipment {
          id
          name
          description
          origin
          destination
          trackingUrl
          containerNum
        }
        deliveryPoint {
          id
          name
          address
          description
          country {
            id
            name
          }
          type
          price
          blocked
        }
        canceledBy {
          role
          name
          canceledDate
          raison
        }
        oldTotalPrice
        oldSubtotalPrice
        oldDeliveryFee
        reshaped
      }
      refunded
      refundedAt
      comment
      refundMethod
      partner {
        lastName
        firstName
      }
    }
  }
`;

const MARK_AS_REFUNDED = gql`
  mutation markAsRefund(
    $id: ID
    $refundedAt: Float
    $comment: String
    $refundMethod: String
  ) {
    markAsRefund(
      id: $id
      refundedAt: $refundedAt
      comment: $comment
      refundMethod: $refundMethod
    ) {
      id
      store {
        id
        name
      }
      client {
        lastName
        firstName
      }
      command {
        id
        num
        totalPrice
        reshaped
        oldTotalPrice
      }
      refunded
      partner {
        lastName
        firstName
      }
    }
  }
`;

export const useMutationRefund = () => {
  const [update] = useMutation(MARK_AS_REFUNDED);

  function markAsRefund({ id, refundedAt, comment, refundMethod }) {
    return update({
      variables: { id, refundedAt, comment, refundMethod },
      fetchPolicy: "no-cache",
    });
  }

  return {
    markAsRefund,
  };
};

export const useQueryRefund = (id) => {
  const { data, ...rest } = useQuery(REFUND, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getRefund, ...rest };
};
